import { useTranslation } from 'react-i18next';

import SEO from 'components/seo';
import { PartnersProvider } from 'contexts/partners/partners.context';
import { MainContentArea, Container, Row } from 'assets/styles/pages.style';
import PartnersHeading from 'components/partners/heading/heading';
import FilterList from 'components/partners/filter-list/filter-list';
import PartnersList from 'components/partners/partners-list/partners-list';
import Proposal from 'components/partners/proposal/proposal';

export default function Partners() {
  return (
    <MainContentArea pt={[3, 8]}>
      <Container>
        <Row mb={[4, 6]}>
          <PartnersHeading />
        </Row>

        <PartnersProvider>
          <Row mb={[0, 4]}>
            <FilterList />
          </Row>
          <Row mb={5}>
            <PartnersList />
          </Row>
        </PartnersProvider>

        <Row>
          <Proposal />
        </Row>
      </Container>
    </MainContentArea>
  );
}

export const Head = () => {
  const { t } = useTranslation();
  return <SEO title={t('pageTitles.partners.title')} />;
};
