import { useTranslation } from 'react-i18next';

import { Button } from 'components/common/button/button';
import { Container, Title, Content } from './proposal.styles';

const Proposal = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title weight="bold">{t('partners.becomeAPartner')}</Title>
      <Content>{t('partners.becomeAPartnerDetails')}</Content>
      <Button as="a" href="mailto:support@minerall.io" target="_blank">
        {t('partners.suggestIdea')}
      </Button>
    </Container>
  );
};

export default Proposal;
