import { useTranslation } from 'react-i18next';

import { usePartnersContext } from 'contexts/partners/partners.context';
import { Item } from './filter-item.styles';

const FilterItem = ({ item, handler }) => {
  const { t } = useTranslation();
  const filterName = `partners.${item}`;

  const { setActiveGroup, filterByGroup, activeGroups } = usePartnersContext();

  const handleFiltering = (groupItem) => {
    setActiveGroup(groupItem);
    filterByGroup(groupItem);
  };

  return (
    <Item
      isActive={activeGroups.includes(item)}
      onClick={() => (handler ? handler() : handleFiltering(item))}
    >
      {t(filterName)}
    </Item>
  );
};

export default FilterItem;
