import { useTranslation } from 'react-i18next';

import { Text } from 'components/common/text/text';
import { Container, Anchor } from './partners-item.styles';

const PartnersItem = ({ partner }) => {
  const { t } = useTranslation();
  const { title, description, url, icon } = partner;

  const formattedLink = url.replace('https://', '').replace('www.', '');

  return (
    <Container>
      {icon}
      <Text size="base" weight="semi">
        {t(`${title}`)}
      </Text>
      <p>{t(`${description}`)}</p>
      <Anchor href={url}>{formattedLink}</Anchor>
    </Container>
  );
};

export default PartnersItem;
