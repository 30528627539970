import {
  NearIcon,
  NicehashIcon,
  ExmoIcon,
  CexIcon,
  FibreCentreIcon,
  MiningRigRentalsIcon,
  PerfectMineIcon,
  MinerBabeIcon
} from 'components/icons/partners';

export const partnersDataArr = [
  {
    title: 'partners.Near',
    description: 'partners.NearInfo',
    url: 'https://near.org',
    icon: <NearIcon />,
    group: ['all', 'decentralisedNetworks']
  },
  {
    title: 'partners.Nicehash',
    description: 'partners.NicehashInfo',
    url: 'https://www.nicehash.com',
    icon: <NicehashIcon />,
    group: ['all', 'miningMarketplace']
  },
  {
    title: 'partners.Exmo',
    description: 'partners.ExmoInfo',
    url: 'https://exmo.com',
    icon: <ExmoIcon />,
    group: ['all', 'cryptocurrencyExchange']
  },
  {
    title: 'partners.CEX',
    description: 'partners.CEXInfo',
    url: 'https://cex.io',
    icon: <CexIcon />,
    group: ['all', 'cryptocurrencyExchange']
  },
  {
    title: 'partners.FibreCentre',
    description: 'partners.FibreCentreInfo',
    url: 'https://fibrecentre.com',
    icon: <FibreCentreIcon />,
    group: ['all', 'miningHotel']
  },
  {
    title: 'partners.MiningRigRentals',
    description: 'partners.MiningRigRentalsInfo',
    url: 'https://www.miningrigrentals.com',
    icon: <MiningRigRentalsIcon />,
    group: ['all', 'miningMarketplace']
  },
  {
    title: 'partners.PerfectMine',
    description: 'partners.PerfectMineInfo',
    url: 'https://perfectmine.io',
    icon: <PerfectMineIcon />,
    group: ['all', 'miningOS']
  },
  {
    title: 'partners.MinerBabe',
    description: 'partners.MinerBabeInfo',
    url: 'https://minerbabe.com',
    icon: <MinerBabeIcon />,
    group: ['all', 'miningOS']
  }
];

export const partnersData = [
  {
    title: 'partners.Near',
    description: 'partners.NearInfo',
    url: 'https://near.org',
    icon: <NearIcon />,
    group: 'decentralisedNetworks'
  },
  {
    title: 'partners.Nicehash',
    description: 'partners.NicehashInfo',
    url: 'https://www.nicehash.com',
    icon: <NicehashIcon />,
    group: 'miningMarketplace'
  },
  {
    title: 'partners.Exmo',
    description: 'partners.ExmoInfo',
    url: 'https://exmo.com',
    icon: <ExmoIcon />,
    group: 'cryptocurrencyExchange'
  },
  {
    title: 'partners.CEX',
    description: 'partners.CEXInfo',
    url: 'https://cex.io',
    icon: <CexIcon />,
    group: 'cryptocurrencyExchange'
  },
  {
    title: 'partners.FibreCentre',
    description: 'partners.FibreCentreInfo',
    url: 'https://fibrecentre.com',
    icon: <FibreCentreIcon />,
    group: 'miningHotel'
  },
  {
    title: 'partners.MiningRigRentals',
    description: 'partners.MiningRigRentalsInfo',
    url: 'https://www.miningrigrentals.com',
    icon: <MiningRigRentalsIcon />,
    group: 'miningMarketplace'
  },
  {
    title: 'partners.PerfectMine',
    description: 'partners.PerfectMineInfo',
    url: 'https://perfectmine.io',
    icon: <PerfectMineIcon />,
    group: 'miningOS'
  },
  {
    title: 'partners.MinerBabe',
    description: 'partners.MinerBabeInfo',
    url: 'https://minerbabe.com',
    icon: <MinerBabeIcon />,
    group: 'miningOS'
  }
];

const filters = [
  'all',
  'miningHotel',
  'cryptocurrencyExchange',
  'miningMarketplace',
  'miningOS',
  'decentralisedNetworks'
];

export const partnersState = {
  filters,
  partners: partnersData,
  filteredPartners: partnersData,
  activeGroups: ['all']
};
