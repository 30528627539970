import { createContext, useContext, useReducer, useMemo } from 'react';

import { partnersState } from './partners.state';
import { partnersReducer } from './partners.reducer';

const PartnersContext = createContext(partnersState);

PartnersContext.displayName = 'PartnersContext';

export const PartnersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(partnersReducer, partnersState);

  const showAll = () => dispatch({ type: 'SHOW_ALL' });
  const filterByGroup = (group) =>
    dispatch({ type: 'FILTER_BY_GROUP', payload: group });
  const setActiveGroup = (activeGroup) =>
    dispatch({ type: 'SET_ACTIVE_GROUP', payload: activeGroup });

  const value = useMemo(
    () => ({
      ...state,
      showAll,
      filterByGroup,
      setActiveGroup
    }),
    [state]
  );

  return (
    <PartnersContext.Provider value={value}>
      {children}
    </PartnersContext.Provider>
  );
};

export const usePartnersContext = () => {
  const context = useContext(PartnersContext);

  if (context === undefined) {
    throw new ReferenceError('Use PartnersContext inside its provider.');
  }

  return context;
};
