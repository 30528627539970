import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  justify-content: flex-start;
  // flex-wrap: wrap;
  gap: 20px;

  padding-bottom: 20px;

  overflow-x: scroll;
`;
