import { usePartnersContext } from 'contexts/partners/partners.context';
import { Container } from './partners-list.styles';
import PartnersItem from '../partners-item/partners-item';

const PartnersList = () => {
  const { filteredPartners } = usePartnersContext();

  return (
    <Container>
      {filteredPartners.map((partner) => {
        return <PartnersItem key={partner.title} partner={partner} />;
      })}
    </Container>
  );
};

export default PartnersList;
