export const partnersReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_ALL': {
      return {
        ...state,
        filteredPartners: state.partners,
        activeGroups: ['all']
      };
    }

    case 'FILTER_BY_GROUP': {
      return {
        ...state,
        filteredPartners: state.partners.filter((partner) =>
          state.activeGroups.includes(partner.group)
        )
      };
    }

    case 'SET_ACTIVE_GROUP': {
      if (state.activeGroups.includes(action.payload)) {
        return {
          ...state,
          activeGroups: state.activeGroups.filter(
            (groupItem) => groupItem !== action.payload
          )
        };
      }

      if (state.activeGroups.includes('all') && action.payload) {
        return {
          ...state,
          activeGroups: [action.payload]
        };
      }

      return {
        ...state,
        activeGroups: [...state.activeGroups, action.payload]
      };
    }

    default: {
      return state;
    }
  }
};
