import { useTranslation } from 'react-i18next';

import { Container, Title, StyledText } from './heading.style';

const PartnersHeading = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title color="text.light" weight="semi">
        {t('partners.title')}
      </Title>

      <StyledText color="text.light">{t('partners.description')}</StyledText>
    </Container>
  );
};

export default PartnersHeading;
