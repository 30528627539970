import { useLayoutEffect } from 'react';

import { usePartnersContext } from 'contexts/partners/partners.context';
import FilterItem from './filter-item/filter-item';
import { Container } from './filter-list.styles';

const FilterList = () => {
  const { filters: allFilters, showAll, activeGroups } = usePartnersContext();
  const filters = allFilters.slice(1);

  useLayoutEffect(() => {
    if (activeGroups.length === 0) {
      showAll();
    }
  }, [activeGroups]);

  return (
    <Container>
      <FilterItem key={allFilters[0]} item={allFilters[0]} handler={showAll} />
      {filters.map((filter) => (
        <FilterItem key={filter} item={filter} />
      ))}
    </Container>
  );
};

export default FilterList;
