import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Text } from 'components/common/text/text';

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 20px;
  }
`;

export const Title = styled(Text)`
  text-transform: uppercase;
  font-size: ${themeGet('fontSizes.up')}px;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    font-size: ${themeGet('fontSizes.xl')}px;
  }
`;

export const Content = styled(Text)`
  max-width: 775px;
  font-size: ${themeGet('fontSizes.base')}px;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    font-size: ${themeGet('fontSizes.mdUp')}px;
  }
`;
