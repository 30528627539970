import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import { Text } from 'components/common/text/text';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 360px;
  padding: 20px;
  border-radius: ${themeGet('radii.base')};

  background-color: ${themeGet('colors.primary.extra')};

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    justify-content: flex-start;
    padding: 66px 0 0;
  }
`;

export const Title = styled(Text)`
  margin-bottom: 36px;

  font-size: ${themeGet('fontSizes.up')}px;
  text-transform: uppercase;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    font-size: ${themeGet('fontSizes.lg')}px;
  }
`;

export const StyledText = styled(Text)`
  text-align: center;
  font-size: ${themeGet('fontSizes.base')}px;

  @media ${themeGet('mediaQueries.tabletLandscapeUp')} {
    max-width: 700px;

    text-align: center;
    font-size: ${themeGet('fontSizes.mdUp')}px;
  }
`;
