import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link } from 'gatsby';

export const Container = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 350px;

  height: 400px;
  padding: 20px;
  border-radius: ${themeGet('radii.base')};

  background-color: ${themeGet('colors.secondary.regular')};
  box-shadow: ${themeGet('shadows.section')};

  text-align: center;

  @media ${themeGet('mediaQueries.tabletPortraitUp')} {
    padding: 40px 20px 17px;
  }
`;

export const Anchor = styled(Link)`
  font-size: ${themeGet('fontSizes.xs')}px;
  font-weight: ${themeGet('fontWeights.bold')};
`;
