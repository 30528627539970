import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Item = styled.li`
  padding: 18px 20px;

  border-radius: ${themeGet('radii.base')};
  border: 1px solid ${themeGet('colors.input.placeholder')};

  color: ${(props) =>
    props.isActive
      ? themeGet('colors.text.light')
      : themeGet('colors.primary.regular')};
  background-color: ${(props) =>
    props.isActive ? themeGet('colors.primary.regular') : 'transparent'};
  font-weight: ${themeGet('fontWeights.bold')};
  cursor: pointer;

  white-space: nowrap;

  &:hover {
    background-color: ${themeGet('colors.primary.regular')};
    color: ${themeGet('colors.text.light')};
  }
`;
